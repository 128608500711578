// import { getCurrency } from 'Util/App';
// import BrowserDatabase from 'Util/BrowserDatabase';

import {
  SET_MODEL_VISIBLE,
  SET_MODEL_CONTENT,
  SET_NUDGE_VISIBLE,
  SET_NUDGE_CONTENT,
  SET_NUDGE_HIDE_BY_USER,
  SET_IS_INSTA_BUY_AVAILABLE,
  SET_IS_TIMER_RUNNING,
  SET_IS_PROMO_API_CALL,
} from "./InstaBuyCoupon.action";

export const getInitialState = () => ({
  isModelVisible: false,
  modelContent: {},
  isNudgeVisible: false,
  NudgeContent: {},
  isNudgeHideByUser: false,
  isInstaBuyAvailable: false,
  isTimerRunning: false,
  isPromoCall: false,
});

export const InstaBuyCouponReducer = (state = getInitialState(), action) => {
  const { type } = action;

  switch (type) {
    case SET_MODEL_VISIBLE:
      const { isModelVisible } = action;

      return {
        ...state,
        isModelVisible,
      };
    case SET_MODEL_CONTENT:
      const { modelContent } = action;

      return {
        ...state,
        modelContent,
      };
    case SET_NUDGE_VISIBLE:
      const { isNudgeVisible } = action;

      return {
        ...state,
        isNudgeVisible,
      };
    case SET_NUDGE_CONTENT:
      const { NudgeContent } = action;

      return {
        ...state,
        NudgeContent,
      };
    case SET_NUDGE_HIDE_BY_USER:
      const { isNudgeHideByUser } = action;

      return {
        ...state,
        isNudgeHideByUser,
      };
    case SET_IS_INSTA_BUY_AVAILABLE:
      const { isInstaBuyAvailable } = action;

      return {
        ...state,
        isInstaBuyAvailable,
      };
    case SET_IS_TIMER_RUNNING:
      const { isTimerRunning } = action;

      return {
        ...state,
        isTimerRunning,
      };
    case SET_IS_PROMO_API_CALL:
      const { isPromoCall } = action;

      return {
        ...state,
        isPromoCall,
      };

    default:
      return state;
  }
};

export default InstaBuyCouponReducer;
