import { getStore } from "Store";
import CartDispatcher from "Store/Cart/Cart.dispatcher";
import { showNotification } from "Store/Notification/Notification.action";
import { getCountryFromUrl } from "Util/Url";

import BrowserDatabase from "Util/BrowserDatabase";

import {
  setModelVisible,
  setModelContent,
  setNudgeVisible,
  setNudgeContent,
  setNudgeHideByUser,
  setIsInstaBuyAvailable,
  setIsTimerRunning,
  setIsPromoCall,
} from "Store/InstaBuyCoupon/InstaBuyCoupon.action";

import { generateCoupon } from "Util/API/endpoint/InstaBuyCoupon/InstaBuyCoupon.endpoint";

import { isSignedIn } from "Util/Auth";
import Logger from "Util/Logger";

export class InstaBuyCouponDispatcher {
  timer = null;

  async getTimerCoupon(dispatch) {
    const customer = BrowserDatabase.getItem("customer") || {};
    const userId = customer?.id;
    const {
      Cart: { cartId, cartTotals:{ subtotal = 0} },
      AppConfig : {
        config={}
      }= {}
    } = getStore().getState();

    const countryCode = getCountryFromUrl();
    const instabuyCouponConfigData =
      config?.countries?.[countryCode]?.insta_coupon;
    const { cart_value = 800 } = instabuyCouponConfigData;

    if (subtotal < cart_value) {
      return;
    }

    const resp = await generateCoupon({ cartId, userId });

    if (resp && resp?.data && resp?.statusCode === 200) {
      dispatch(setModelVisible(true));
      const data = resp?.data;
      dispatch(setModelContent(data));
      const NudgeContent = {
        // campaignType : campaign_type,
        couponCode: data?.coupon_code,
        instabuyTitle: data?.title,
        instabuyDescription: data?.description,
        // description : data?.description,
        // descriptionAr : data?.description,
        startTime: data?.start_date,
        endTime: data?.end_date,
        TermsAndCond: data?.terms,
      };
      dispatch(setNudgeContent(NudgeContent));
      dispatch(setIsInstaBuyAvailable(true));
    }
  }

  setTimerCopon(dispatch) {
    const {
      AppConfig : {
        config={}
      }= {}
    } = getStore().getState();

    const countryCode = getCountryFromUrl();
    const instabuyCouponConfigData = config?.countries?.[countryCode]?.insta_coupon;
    const { session_time = 2 } = instabuyCouponConfigData;

    if (!this.timer) {
      setIsTimerRunning(dispatch, true);
      this.timer = setTimeout(() => {
        this.getTimerCoupon(dispatch);
        this.timer = null;
        setIsTimerRunning(dispatch, false);
      }, (session_time * 60 ) * 1000);
    }
  }

  setInstbuyAvailable(dispatch, InstbuyCoupons = {}) {
    const {
      // campaign_type = "",
      code = "",
      description = "",
      description_ar = "",
      end_date_time = "",
      instabuy_description = "",
      instabuy_title = "",
      start_date_time = "",
      term_and_cond = "",
    } = InstbuyCoupons;

    const NudgeContent = {
      // campaignType : campaign_type,
      couponCode: code,
      instabuyTitle: instabuy_title,
      instabuyDescription: instabuy_description,
      description: description,
      descriptionAr: description_ar,
      startTime: start_date_time,
      endTime: end_date_time,
      TermsAndCond: term_and_cond,
    };
    // put check here according to end coupon time
    dispatch(setNudgeVisible(true));
    dispatch(setNudgeContent(NudgeContent));
    dispatch(setIsInstaBuyAvailable(true));
  }

  checkIsInstabuyAvailable(dispatch, avail_coupons=[]) {
    const {
      Cart: {
        cartTotals: { subtotal = 0 },
      },
      AppConfig: { config = {} } = {},
    } = getStore().getState();
    const countryCode = getCountryFromUrl();
    const instabuyCouponConfigData =
      config?.countries?.[countryCode]?.insta_coupon;
    const { cart_value = 800 } = instabuyCouponConfigData;

    const InstbuyCoupon = avail_coupons.filter(
      (coupon) => coupon.campaign_type === "ACTIVITY_BASED"
    );

    if (InstbuyCoupon && InstbuyCoupon.length > 0) {
      this.setInstbuyAvailable(dispatch, InstbuyCoupon[0]);
    } else {
      const path =
        location.pathname.match(/checkout/) || location.pathname.match(/cart/);

      if (path || subtotal < cart_value) {
        return;
      }
      // generate instabuy coupon - write the logic here - when to call generate coupon?
      this.setTimerCopon(dispatch);
    }
  }

  setModelVisibility = (dispatch, isVisible) => {
    dispatch(setModelVisible(isVisible));
  };

  setNudgeVisibility = (dispatch, isVisible) => {
    dispatch(setNudgeVisible(isVisible));
  };

  setNudgeHideByUser = (dispatch, isNudgeHideByUser) => {
    dispatch(setNudgeHideByUser(isNudgeHideByUser));
  };

  setIsInstaBuyAvailable = (dispatch, isInstaBuyAvailable) => {
    dispatch(setIsInstaBuyAvailable(isInstaBuyAvailable));
  };

  setIsTimerRunning = (dispatch, isTimerRunning) => {
    dispatch(setIsTimerRunning(isTimerRunning));
  };

  setIsPromoCall = (dispatch, isPromoCall) => {
    dispatch(setIsPromoCall(isPromoCall));
  };

  clearInstabuy = (dispatch) => {
    dispatch(setNudgeVisible(false));
    dispatch(setNudgeContent({}));
    dispatch(setModelVisible(false));
    dispatch(setModelContent({}));
    dispatch(setIsInstaBuyAvailable(false));
  };
}

export default new InstaBuyCouponDispatcher();
