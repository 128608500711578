import MobileAPI from "../../provider/MobileAPI";
import ThirdPartyAPI from "../../provider/ThirdPartyAPI";

export const genrateModyAiId = () => ThirdPartyAPI.post("/modyai/u") || {};

export const setModyAiIdToBackend = (data) =>
  MobileAPI.post("/mody", data) || {};

export const createNewMeasurement = (modyID, payload) =>
  ThirdPartyAPI.patch(
    `/modyai/u/${modyID}`,
    payload,
    {},
    { "X-User": modyID }
  ) || {};

export const generateModyMeasID = (modyID, payload, entityId) =>
  ThirdPartyAPI.post(
    `/modyai/e/${entityId}/meas`,
    payload,
    {},
    { "X-User": modyID }
  ) || {};

export const getModyMeasurements = (modyId, measId, entityId) =>
  ThirdPartyAPI.get(
    `/modyai/e/${entityId}/meas/${measId}/similarity`,
    // {},
    { "X-User": modyId }
  ) || {};

export const deleteModyProfile = (modyId) =>
  MobileAPI.delete(`/mody/${modyId}`) || {};

export const checkProductSKUInMody = (modyId, itemID, entityId) =>
  ThirdPartyAPI.get(
    `/modyai/e/${entityId}/items/${itemID}`,
    // {},
    { "X-User": modyId }
  ) || {};

export const sendSKUInBackend = (objectID) =>
  MobileAPI.post(`/mody/product/${objectID}`) || {};
