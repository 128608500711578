import Event, { EVENT_GTM_PII_DATA_COLLECTED } from "Util/Event";
import BaseEvent from "./Base.event";
import BrowserDatabase from "Util/BrowserDatabase";
import { sha256 } from "js-sha256";
import { isSignedIn } from "Util/Auth";

export const SPAM_PROTECTION_DELAY = 200;

class PIIDataCollected extends BaseEvent {
  bindEvent() {
    Event.observer(EVENT_GTM_PII_DATA_COLLECTED, ({ customer_data_info }) => {
      this.handle({ customer_data_info });
    });
  }

  handler({ customer_data_info }) {
    if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
      return;
    }

    const customer_data = !customer_data_info
      ? JSON.parse(localStorage.getItem("customer")).data
      : customer_data_info;
    const currentSelectedAddress = BrowserDatabase.getItem(
      "currentSelectedAddress"
    );

    const nonLoggedInUserSelected = {
      email_address_hashed: currentSelectedAddress?.email
        ? sha256(currentSelectedAddress?.email.trim())
        : null,
      phone_number_hashed: currentSelectedAddress?.phone
        ? sha256(currentSelectedAddress?.phone.split("+")[1])
        : null,

      first_name_hashed: currentSelectedAddress?.firstname
        ? sha256(currentSelectedAddress?.firstname?.toLowerCase().trim())
        : null,

      last_name_hashed:
        currentSelectedAddress?.lastname &&
        currentSelectedAddress?.lastname != "LASTNAME_PLACEHOLDER"
          ? sha256(currentSelectedAddress?.lastname?.toLowerCase().trim())
          : null,

      gender:
        customer_data?.gender == 0
          ? sha256("m")
          : customer_data?.gender == 1
          ? sha256("f")
          : customer_data?.gender == 2
          ? sha256("")
          : null,
    };

    const loggedInUserInfo = {
      email_address_hashed: customer_data?.email
        ? sha256(customer_data?.email.trim())
        : null,
      phone_number_hashed: customer_data?.phone
        ? sha256(customer_data?.phone.split("+")[1])
        : null,

      first_name_hashed: customer_data?.firstname
        ? sha256(customer_data?.firstname?.toLowerCase().trim())
        : null,

      last_name_hashed:
        customer_data?.lastname &&
        customer_data?.lastname != "LASTNAME_PLACEHOLDER"
          ? sha256(customer_data?.lastname?.toLowerCase().trim())
          : null,

      gender:
        customer_data?.gender == 0
          ? sha256("m")
          : customer_data?.gender == 1
          ? sha256("f")
          : customer_data?.gender == 2
          ? sha256("")
          : null,
    };

    const hashedData = isSignedIn()
      ? loggedInUserInfo
      : nonLoggedInUserSelected;

    this.pushEventData(hashedData);
  }
}

export default PIIDataCollected;
