import Event, { EVENT_WALLET_REDEMPTIONS_CLICK } from "Util/Event";

import BaseEvent from "./Base.event";

export const SPAM_PROTECTION_DELAY = 200;

class WalletRedemptionsClick extends BaseEvent {
  bindEvent() {
    Event.observer(EVENT_WALLET_REDEMPTIONS_CLICK, () => {
      this.handle();
    });
  }

  handler() {
    if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
      return;
    }

    this.pushEventData();
  }
}

export default WalletRedemptionsClick;
