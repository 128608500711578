import Event, { EVENT_HANDLING_FEE_CLICK } from "Util/Event";

import BaseEvent from "./Base.event";

export const SPAM_PROTECTION_DELAY = 200;

class HandlingFeeClick extends BaseEvent {
  bindEvent() {
    Event.observer(EVENT_HANDLING_FEE_CLICK, ({ platform_fee }) => {
      this.handle({ platform_fee });
    });
  }

  handler({ platform_fee }) {
    if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
      return;
    }

    this.pushEventData({
      platform_fee,
    });
  }
}

export default HandlingFeeClick;
