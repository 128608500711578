export const SET_MODEL_VISIBLE = "SET_MODEL_VISIBLE";
export const SET_MODEL_CONTENT = "SET_MODEL_CONTENT";
export const SET_NUDGE_VISIBLE = "SET_NUDGE_VISIBLE";
export const SET_NUDGE_CONTENT = "SET_NUDGE_CONTENT";
export const SET_NUDGE_HIDE_BY_USER = "SET_NUDGE_HIDE_BY_USER";
export const SET_IS_INSTA_BUY_AVAILABLE = "SET_IS_INSTA_BUY_AVAILABLE";
export const SET_IS_TIMER_RUNNING = "SET_IS_TIMER_RUNNING";
export const SET_IS_PROMO_API_CALL = "SET_IS_PROMO_API_CALL";

export const setModelVisible = (isModelVisible) => ({
  type: SET_MODEL_VISIBLE,
  isModelVisible,
});

export const setModelContent = (modelContent) => ({
  type: SET_MODEL_CONTENT,
  modelContent,
});

export const setNudgeVisible = (isNudgeVisible) => ({
  type: SET_NUDGE_VISIBLE,
  isNudgeVisible,
});

export const setNudgeContent = (NudgeContent) => ({
  type: SET_NUDGE_CONTENT,
  NudgeContent,
});

export const setNudgeHideByUser = (isNudgeHideByUser) => ({
  type: SET_NUDGE_HIDE_BY_USER,
  isNudgeHideByUser,
});

export const setIsInstaBuyAvailable = (isInstaBuyAvailable) => ({
  type: SET_IS_INSTA_BUY_AVAILABLE,
  isInstaBuyAvailable,
});

export const setIsTimerRunning = (isTimerRunning) => ({
  type: SET_IS_TIMER_RUNNING,
  isTimerRunning,
});

export const setIsPromoCall = (isPromoCall) => ({
  type: SET_IS_PROMO_API_CALL,
  isPromoCall,
});
