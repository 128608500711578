// TODO update this import when renamed
import {
    SET_PDP_DATA,
    SET_PDP_GALLERY_IMAGE_INDEX,
    SET_PDP_LOADING,
    SET_PDP_CLICK_AND_COLLECT,
    SET_DISPLAY_SEARCH,
    SET_BRAND_INFO_DATA,
    SET_BRAND_BUTTON_CLICK,
    SET_VUE_TRENDING_BRAND_CLICK,
    SET_NEW_DESIGN,
    SET_ADDTOCART_INFO,
    SET_SELECTEDSIZE_INFO,
    SET_PDPJSON_DATA,
    SET_MODY_DATA,
} from './PDP.action';

export const getInitialState = () => ({
    product: {},
    options: {},
    imageIndex: 0, // positive numbers - gallery, negative numbers - special cases, i.e. video = -1
    clickAndCollectStores: [],
    isLoading: true,
    displaySearch: false,
    brandInfoData: '',
    brandButtonClick : false,
    vueTrendingBrandClick: false,
    isNewDesign: false,
    addtoCartInfo:{ is_flash_sale : false },
    isSelectedSizeInfo:{},
    PDPJsonData:{},
    modyAiData: {},
});

export const PDPReducer = (state = getInitialState(), action) => {
    const { type } = action;
    switch (type) {
        case SET_PDP_DATA:
            const {
                response: {
                    data: product = {},
                    nbHits
                },
                options = {}
            } = action;

            return {
                ...state,
                imageIndex: 0,
                product,
                options,
                nbHits,
                isLoading:true
            };

        case SET_PDP_GALLERY_IMAGE_INDEX:
            const { imageIndex } = action;

            return {
                ...state,
                imageIndex
            };

        case SET_PDP_CLICK_AND_COLLECT:
            const { clickAndCollectStores } = action;
            return {
                ...state,
                clickAndCollectStores
            };

        case SET_BRAND_INFO_DATA:
            const { data } = action;
            return {
                ...state,
                brandInfoData: data,
            };

        case SET_PDP_LOADING:
            const { isLoading } = action;

            return {
                ...state,
                isLoading
            };

        case SET_DISPLAY_SEARCH:
            const { displaySearch } = action;

            return {
                ...state,
                displaySearch
            };

        case SET_BRAND_BUTTON_CLICK:
            const { brandButtonClick } = action;

            return {
                ...state,
                brandButtonClick
            };

        case SET_VUE_TRENDING_BRAND_CLICK:
            const { vueTrendingBrandClick } = action;
    
            return {
                ...state,
                vueTrendingBrandClick
            };

        case SET_NEW_DESIGN:
            const { isNewDesign } = action;
            return {
                ...state,
                isNewDesign
            };

        case SET_ADDTOCART_INFO:
            const { addtoCartInfo } = action;
            let getCartObject = addtoCartInfo.isForceToEmpty ? { is_flash_sale : false } : state.addtoCartInfo;
            return {
                ...state,
                addtoCartInfo:{
                    ...getCartObject,
                    ...addtoCartInfo
                }
            };
        case SET_SELECTEDSIZE_INFO:
            const { isSelectedSizeInfo } = action;
            let getSizeObject = isSelectedSizeInfo.isForceToEmpty ? {}  : state.isSelectedSizeInfo;
            return {
                ...state,
                isSelectedSizeInfo:{
                    ...getSizeObject,
                    ...isSelectedSizeInfo
                }
            };
        case SET_PDPJSON_DATA:
            const { PDPJsonData } = action;
            // let getSizeObject = isSelectedSizeInfo.isForceToEmpty ? {}  : state.isSelectedSizeInfo;
            return {
                ...state,
                PDPJsonData:{
                    ...PDPJsonData
                }
            };
        
        case SET_MODY_DATA:
            const { modyAiData } = action;
            return {
                ...state,
                modyAiData
            };

        default:
            return state;
    }
};

export default PDPReducer;
