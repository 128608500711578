import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

export class MyAccountQuery extends SourceMyAccountQuery {
    _getCustomerFields() {
        return [
            'created_at',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'referral_coupon',
            'user_segment',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            `custom_attributes {
                is_mobile_otp_verified
            }`,
            `mody_user_id_map {
                uuid,
                gender,
                is_selected,
                profile_data {
                    name,
                    height,
                    weight,
                    country,
                    source,
                    fit,
                    size,
                    name,
                    height_unit,
                    weight_unit,
                    age,
                }
            }`,
            this._getAddressesField()
        ];
    }

    getSignInMutation(options) {
        const { email, password } = options;

        return new Field('generateCustomerToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addField('token');
    }
}

export default new MyAccountQuery();
