import { HOME_PAGE_BANNER_CLICK_IMPRESSIONS } from "Component/GoogleTagManager/events/BannerImpression.event";
import Image from "Component/Image";
import Link from "Component/Link";
import Price from "Component/Price";
import WishlistIcon from "Component/WishlistIcon";
import PropTypes from "prop-types";
import Logger from "Util/Logger";
import VueIntegrationQueries from "Query/vueIntegration.query";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { isArabic } from "Util/App";
import { getCurrency } from "Util/App/App";
import { getUUID } from "Util/Auth";
import Event, {
  EVENT_GTM_VUE_PRODUCT_CLICK,
  EVENT_PDP_SELECT_PROMOTION,
  VUE_CAROUSEL_CLICK,
  EVENT_PDP_MOE_RECOMMEDATION_WIDGET_VIEW_ITEM,
  MOE_trackEvent
} from "Util/Event";
import { parseURL } from "Util/Url";
import { setPDPData } from "Store/PDP/PDP.action";
import ExpressDeliveryTag from "Component/ExpressDeliveryTag";
import BrowserDatabase from "Util/BrowserDatabase";
import { APP_STATE_CACHE_KEY } from "Store/AppState/AppState.reducer";

export const mapStateToProps = (state) => ({
  country: state.AppState.country,
  language: state.AppState.language,
});

export const mapDispatchToProps = (dispatch) => ({
  setPDPData: (response, options) => dispatch(setPDPData(response, options)),
});

class DynamicContentVueProductSliderItem extends PureComponent {
  static propTypes = {
    country: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    pageType: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {
      isArabic: isArabic(),
    };
  }
  getPageType() {
    const { urlRewrite, currentRouteName } = window;

    if (currentRouteName === "url-rewrite") {
      if (typeof urlRewrite === "undefined") {
        return "";
      }

      if (urlRewrite.notFound) {
        return "notfound";
      }

      return (urlRewrite.type || "").toLowerCase();
    }

    return (currentRouteName || "").toLowerCase();
  }

  onclick = (widgetID, item) => {
    const {
      pageType,
      // data: { category, sku, link },
      data,
      posofreco,
      sourceProdID,
      sourceCatgID,
      index,
      setPDPData,
      language,
      widgetheading
    } = this.props;
    const {brand_name, category, sku, link, price, name, gender } = data;
    let destProdID = sku;
   
    // vue analytics
    try {
      const locale = VueIntegrationQueries.getLocaleFromUrl();
      const itemPrice = price[0][Object.keys(price[0])[0]]["6s_special_price"];
      const basePrice = price[0][Object.keys(price[0])[0]]["6s_base_price"];
  
      VueIntegrationQueries.vueAnalayticsLogger({
        event_name: VUE_CAROUSEL_CLICK,
        params: {
          event: VUE_CAROUSEL_CLICK,
          pageType: pageType,
          currency: VueIntegrationQueries.getCurrencyCodeFromLocale(locale),
          clicked: Date.now(),
          uuid: getUUID(),
          referrer: window.location.href,
          url: link ? link : null,
          widgetID: VueIntegrationQueries.getWidgetTypeMapped(
            widgetID,
            pageType
          ),
          sourceProdID: sourceProdID,
          sourceCatgID: sourceCatgID,
          destProdID: destProdID,
          destCategoryID: category,
          prodPrice: itemPrice,
          posofreco: posofreco,
        },
      });
      // const productData = {...data, position: posofreco + 1};
      // Event.dispatch(EVENT_GTM_VUE_PRODUCT_CLICK, productData);

      // if(sessionStorage.getItem("currentScreen") === "product"){
        const currentAppState = BrowserDatabase.getItem(APP_STATE_CACHE_KEY);
        const AppStategender = currentAppState?.gender?.toLowerCase();
        const updateGender = gender ? gender : [AppStategender];
        const screen_name = sessionStorage.getItem("currentScreen");
        const widgetName = widgetID ? `${screen_name}-${updateGender ? [updateGender].join("-").toLowerCase(): ""}-${language}-${widgetID}`  : '';
     
        const selected_promotion = {
          brand_name:brand_name,
          product_sku:sku,
          promotion_id: widgetName,
          promotion_name: widgetName,
          item:[
            {
              item_id:sku ? sku : "",
              item_name:name ? name : "",
              item_brand:brand_name ? brand_name : '',
              item_list_name: widgetName,
              item_category:category ? category : '',
              price:itemPrice ? itemPrice :''
            }
          ]
        }
        const selected_promotion_moe = {
          widget_name:widgetheading,
          product_name:name ?? "",
          tile_selected:index,
          price:itemPrice ? itemPrice :'',
          product_sku:sku ?? '',
          brand_name:brand_name ?? ""
        }
        Event.dispatch(EVENT_PDP_SELECT_PROMOTION,selected_promotion);
        MOE_trackEvent(EVENT_PDP_MOE_RECOMMEDATION_WIDGET_VIEW_ITEM, selected_promotion_moe);
      // }
      this.props.setLastTapItemOnHome(`VeuSliderWrapper${index}`);
      setPDPData({}, {});
    } catch (e) {
      Logger.log(e);
    }

    // this.sendBannerClickImpression(item);
  };
  sendBannerClickImpression(item) {
    Event.dispatch(HOME_PAGE_BANNER_CLICK_IMPRESSIONS, [item]);
  }

  renderPrice(price) {
    const { isArabic } = this.state;
    if (price && price.length > 0) {
      return (
        <div
          block="VueProductSlider"
          elem="SpecialPriceCon"
          mods={{ isArabic }}
        >
          <Price price={price} renderSpecialPrice={true} cart={true} />
        </div>
      );
    }
    return null;
  }

  renderIsNew(is_new_in) {
    if (is_new_in) {
      return (
        <div block="VueProductSlider" elem="VueIsNewTag">
          <span>{__("New")}</span>
        </div>
      );
    }
    return null;
  }
  renderProductTag(productTag) {
    return (
      <div block="VueProductSlider" elem="VueProductTag">
        <span>{__(productTag)}</span>
      </div>
    );
  }

  renderExpressDeliveryTag = (data) => {
    return <ExpressDeliveryTag productInfo={data} />;
  };

  render() {
    const {
      data: {
        category,
        thumbnail_url,
        name,
        brand_name,
        price,
        is_new_in = false,
        sku,
        link = "",
      },
      data,
      widgetID,
      pageType,
      renderMySignInPopup,
      closeCartPopup,
      widgetPosition
    } = this.props;
    
    const { isArabic } = this.state;
    let newLink = link;
    if (data?.url) {
      newLink = data.url;
    }
    let productTag = this.props.data.product_tag
      ? this.props.data.product_tag
      : "";

    return (
      <div
        block="VueProductSlider"
        elem="VueProductContainer"
        mods={{ isArabic }}
        data-sku={sku}
        data-category={category}
        ref={this.childRef}
      >
        <Link
          to={parseURL(newLink)?.pathname?.split("?_ga")[0] || "/"}
          data-banner-type="vueSlider"
          block="VueProductSlider-Link"
          onClick={() => {
            this.onclick(widgetID, data);
          }}
        >
          <div style={{ position: 'relative' }}>
          <Image
            lazyLoad={true}
            block="VueProductSlider"
            elem="VueProductImage"
            src={thumbnail_url}
            alt={name}
          />
            {this.renderIsNew(is_new_in)}
          {productTag
            ? this.renderProductTag(productTag)
            : this.renderIsNew(is_new_in)} 
          </div>
  
          <h6 id="brandName">{brand_name}</h6>
          <span id="productName">{name}</span>
          {this.renderPrice(price)}
  
        
        </Link>
        <WishlistIcon
          renderMySignInPopup={renderMySignInPopup}
          sku={sku}
          data={data}
          pageType={pageType}
          closeCartPopup={closeCartPopup}
          widgetName={widgetID}
          widgetPosition={widgetPosition}
        />
        {this.renderExpressDeliveryTag(data)}
      </div>
    );
  }
} 

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicContentVueProductSliderItem);
