import PropTypes from "prop-types";
import React, { PureComponent, createRef } from "react";
import { connect } from "react-redux";
import { fetchVueData } from "Util/API/endpoint/Vue/Vue.endpoint";
import { isArabic } from "Util/App";
import BrowserDatabase from "Util/BrowserDatabase";
import VueQuery from "../../query/Vue.query";
import Logger from "Util/Logger";
import DynamicContentVueProductSliderContainer from "../DynamicContentVueProductSlider";
import "./DynamicContentVueSlider.style";
import { getUUIDToken, getUUID } from "Util/Auth";

export const mapStateToProps = (state) => ({
  gender: state.AppState.gender,
});

class DynamicContentVueSlider extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        label: PropTypes.string,
        link: PropTypes.string,
        plp_config: PropTypes.shape({}), // TODO: describe
      })
    ),
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isArabic: isArabic(),
      isHideWidget: true,
    };
  }

  timerStartRef = createRef();
  timerEndRef = createRef();

  componentDidMount() {
    let a = this.props;
    this.getPdpWidgetsVueData();
    this.showWidgetPostRender();
  }

  componentWillUnmount() {
    this.timerStartRef.current && clearTimeout(this.timerStartRef.current);
    this.timerEndRef.current && clearTimeout(this.timerEndRef.current);
  }

  showWidgetPostRender = () => {
    const now = new Date();
    const utcString = now.toUTCString();
    const { end_time, start_time } = this.props;
    const finalendDate = end_time;
    const time = Date.parse(finalendDate) - Date.parse(utcString);
    const timeToStart = Date.parse(start_time) - Date.parse(utcString);
    if (timeToStart > 0) {
      this.timerStartRef.current = setTimeout(() => {
        this.setState({ isHideWidget: false })
      }, timeToStart)
      this.timerEndRef.current = setTimeout(() => { this.setState({ isHideWidget: true }); }, time)
    }
    if (time <= 0) {
      this.setState({ isHideWidget: true });
    } else if (Date.parse(start_time) < Date.parse(utcString) && Date.parse(utcString) < Date.parse(finalendDate)) {
      this.setState({ isHideWidget: false });
      this.timerEndRef.current = setTimeout(() => { this.setState({ isHideWidget: true }); }, time)
    }
  }

  getPdpWidgetsVueData = () => {
    const { gender } = this.props;
    const userData = BrowserDatabase.getItem("MOE_DATA");
    const customer = BrowserDatabase.getItem("customer");
    const userID = customer && customer.id ? customer.id : null;
    const query = {
      filters: [],
      num_results: 10,
      mad_uuid: getUUID(),
    };
    let type = this.props.type;
    const payload = VueQuery.buildQuery(type, query, {
      gender,
      userID,
    });
    try {
      fetchVueData(payload)
        .then((resp) => {
          this.setState({
            data: resp.data,
          });
        })
        .catch((err) => {
          console.error("pdp widget vue query catch", err);
        });
    } catch (e) {
      Logger.log(e);
    }
  };

  render() {
    const { isArabic } = this.state;
    const { renderMySignInPopup, index, setLastTapItemOnHome, widgetID } = this.props;
    if (this.state.data?.length === 0 || this.state.data === undefined) {
      return null;
    }

    const { start_time="", end_time="" } = this.props;if (start_time && end_time) {
      if (!this.state.isHideWidget) {
        return (
          <div
            block="VeuSliderWrapper"
            mods={{ isArabic }}
            id={`VeuSliderWrapper${index}`}
          >
            {this.state.data?.length > 0 && (
              <DynamicContentVueProductSliderContainer
                products={this.state.data}
                setLastTapItemOnHome={setLastTapItemOnHome}
                renderMySignInPopup={renderMySignInPopup}
                heading={this.props.layout.title}
                isHome={true}
                pageType={"Home"}
                index={index}
                widgetID={widgetID}
              />
            )}
          </div>
        );
      } else {
        return <div></div>
      }
    }
    return (
      <div
        block="VeuSliderWrapper"
        mods={{ isArabic }}
        id={`VeuSliderWrapper${index}`}
      >
        {this.state.data?.length > 0 && (
          <DynamicContentVueProductSliderContainer
            products={this.state.data}
            setLastTapItemOnHome={setLastTapItemOnHome}
            renderMySignInPopup={renderMySignInPopup}
            heading={this.props.layout.title}
            isHome={true}
            pageType={"Home"}
            index={index}
            widgetID={widgetID}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(DynamicContentVueSlider);
