import Event, { SELECTED_SORT_BY_OPTION } from "Util/Event";
import BaseEvent from "../Base.event"
export const SPAM_PROTECTION_DELAY = 200;

class SelectedSortByOption extends BaseEvent {

    bindEvent() {
        Event.observer( SELECTED_SORT_BY_OPTION, ({ selected_sort }) => {
          this.handle({ selected_sort});
        });
    }

    handler({ selected_sort}) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
          return;
        }
    
        this.pushEventData({selected_sort});
    }
}

export default SelectedSortByOption;

