import Event, { EVENT_MODY_AI_EVENTS } from "Util/Event";
import BaseEvent from "../Base.event";
import BrowserDatabase from "Util/BrowserDatabase";

/**
 * Website places, from where was received event data
 *
 * @type {string}
 */

/**
 * Constants
 *
 * @type {number}
 */
export const SPAM_PROTECTION_DELAY = 200;
export const EVENT_HANDLE_DELAY = 700;
export const URL_REWRITE = "url-rewrite";
export const CART_ITEMS_CACHE_KEY = "CART_ITEMS_CACHE_KEY";
/**
 * GTM PWA Impression Event
 *
 * Called when customer see banners on home page
 */
class ModyAIEvent extends BaseEvent {
  /**
   * Set base event call delay
   *
   * @type {number}
   */
  eventHandleDelay = EVENT_HANDLE_DELAY;

  /**
   * Bind PWA event handling
   */
  bindEvent() {
    Event.observer(EVENT_MODY_AI_EVENTS, (eventDetails) => {
      this.handle(eventDetails);
    });
  }

  handler(event) {
    if (event.name) {
      this.pushEventData({
        event: event.name,
        eventAction: event.name,
        product_sku: event.product_sku || "",
        name: event.modyUserName || "",
        height: event.height || "",
        weight: event.weight || "",
        age: event.age || "",
        mody_profile_id: event.mody_profile_id || "",

        UserType:
          this.getCustomerId().toString().length > 0
            ? "Logged In"
            : "Logged Out",
      });
    }
  }

  getCustomerId() {
    return this.isSignedIn()
      ? this.getAppState().MyAccountReducer.customer.id || ""
      : "";
  }
}

export default ModyAIEvent;
